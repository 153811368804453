<ng-container *ngIf="!normalBtn">
  <ion-button
    mode="md"
    class="app-secondary-btn text-base font-semibold"
    [ngClass]="customClassAppBtn ? customClassAppBtn : '' "
    [type]="type"
    (click)="onClickBtn()"
    [disabled]="isDisabled"
  >
      <span *ngIf="addIcon && addIcon === 'left'" class="mr-2" [ngClass]="iconClass ? iconClass : '' ">
        <ng-content select="[leftIcon]"></ng-content>
      </span>
      {{btnText}}
      <span *ngIf="addIcon && addIcon === 'right'" class="mr-2" [ngClass]="iconClass ? iconClass : '' ">
        <ng-content select="[rightIcon]"></ng-content>
      </span>
  </ion-button>
</ng-container>

<ng-container *ngIf="normalBtn">
  <button (click)="onClickBtn()"
  [disabled]="isDisabled"
  [type]="type"
  class="bg-secondary rounded-lg py-2.5 px-4 shadow-sm hover:bg-secondary_focused text-white ion-activatable relative overflow-hidden flex items-center justify-center border-none"
  [ngClass]="customClassAppBtn ? customClassAppBtn : 'text-base'"
  >
    <ion-ripple-effect></ion-ripple-effect>
    <ng-content ></ng-content>
  </button>
</ng-container>

