<ng-container *ngIf="showMenuLateral">
  <div class="side-bar-custom absolute mt-20 top-0 right-0 hidden lg:block  bg-primary h-screen z-5"
    [ngClass]="{
      'sideBar-full-state  overflow-y-auto pb-24 sidebar-container-shadow' : SideBarActive,
      'lg:w-20 xl:w-24' : !SideBarActive,
      'gradient-menu' : networkIsActive && menuGradientActive
    }"
  >
    <div class="lg:w-20 xl:w-24 mr-0 ml-auto grid content-between"
      [ngClass]="SideBarActive ? 'sidebar-active' : 'sidebar-off'">
      <div class="menu mt-4 ">
        <!-- Icons -->
        <!-- <ng-container *ngIf="false">
          <div class="relative h-11 z-9" *ngFor="let route of array_menu2">
            <div
              class="group side-item  transition duration-300 flex items-center  bg-primary absolute top-0 right-0 w-full h-full"
              (click)="to(route.path)"
              [ngClass]="SideBarActive ? 'justify-start' : 'justify-center hover:w-80'"
            >
              <div
                class="transition delay-100 duration-200 bg-secondary absolute w-11/12 h-full right-0 top-0 left-0 w-full group-hover:w-full group-hover:rounded-l-none"
                *ngIf="page_select == route.path"
              ></div>
              <ion-icon
                [src]="route.icon"
                [ngClass]="SideBarActive ? 'mr-2' : 'group-hover:mr-2'"
              ></ion-icon>
              <span
                class="whitespace-nowrap z-2"
                [ngClass]="SideBarActive ? 'block' : 'hidden group-hover:block'"
                >{{ route.name }}
              </span>
            </div>
          </div>
        </ng-container> -->


        <ng-container *ngIf="networkIsActive else offlineActiveMenu">
          <div class="item mb-4 relative z-50 text-white  " *ngFor="let group of array_menu2; index as i"
            [ngClass]="{
              active: displayAccordionSection === i && group.isGrouped  && SideBarActive,
              'overflow-hidden': SideBarActive,
              'overflow-visible': !SideBarActive,
              'bg-secondary w-4/5 mx-auto rounded-lg': groupSelected === i && !SideBarActive
            }"
          >
            <div (click)="group.isGrouped ? accordeonActive(i) : to(group.groupedItems[0].path, i)"
              class="select-header group  flex items-center  cursor-pointer transition duration-300"
              [ngClass]="{
                'justify-between mx-5 px-5 ': SideBarActive,
                'bg-secondary rounded-sm': groupSelected === i && SideBarActive,
                'hover-path': groupSelected !== i && SideBarActive,
                'justify-center hover:w-84 absolute top-0 right-0 w-full h-full': !SideBarActive,
                'hover:bg-secondary': groupSelected === i && !SideBarActive,
                'inactive-menu-item': groupSelected !== i && !SideBarActive
              }">
              <div class="flex items-center " [ngClass]="SideBarActive ? 'justify-start' : 'justify-center'">
                <ion-icon [src]="group.groupIcon" slot="start" class=" text-xl"

                  [ngClass]="SideBarActive ? 'mr-4' : 'group-hover:mr-4'"
                ></ion-icon>
                <span class=" text-base font-normal"
                  [ngClass]="SideBarActive ? 'block' : 'hidden group-hover:block'">{{group.groupName}}</span>
              </div>
              <ion-icon *ngIf="!group.isGrouped && SideBarActive" name="chevron-forward-outline"
                class="text-base noHover "></ion-icon>
              <ion-icon *ngIf="group.isGrouped && SideBarActive" name="chevron-down-outline"
                [ngClass]="{ 'rotate-180': displayAccordionSection == i, 'rotate-0': displayAccordionSection !== i }"
                class="text-base  transition-transform duration-200 transform noHover">
              </ion-icon>
            </div>
            <ng-container *ngIf="group.isGrouped && SideBarActive">
              <div class="mt-1 ml-14 px-5 mr-5  py-2 flex items-center justify-start leading-9 cursor-pointer"
                *ngFor="let item of group.groupedItems; index as subindex" (click)="to(item.path, null, subindex)" [ngClass]="{
                  'bg-secondary rounded-sm': groupSelected === i && subGroupSelected === subindex  && SideBarActive,
                  'hover-path': (groupSelected !== i &&  SideBarActive ) || (groupSelected === i && subGroupSelected !== subindex && SideBarActive)
                }"
              >

                <ion-icon [src]="item.icon" slot="start" class=" text-xl mr-4"></ion-icon>
                <span class="text-base font-normal max-w-xxs whitespace-nowrap overflow-hidden overflow-ellipsis">{{item.name}}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <ng-template #offlineActiveMenu>
          <div class="item mb-4 relative z-50 text-white  " *ngFor="let group of array_menu2; index as i" [ngClass]="{'overflow-visible': !SideBarActive}">
            <div class="select-header group  flex items-center  cursor-pointer opacity-50 " [ngClass]="{
                'justify-center absolute top-0 right-0 w-full h-full transition ': !SideBarActive,
                'hover:bg-secondary': groupSelected === i && !SideBarActive
              }"
            >
              <div class="flex items-center " [ngClass]="SideBarActive ? 'justify-start' : 'justify-center'">
                <ion-icon [src]="group.groupIcon" slot="start" class=" text-xl"
                  [ngClass]="SideBarActive ? 'mr-4' : ''"
                ></ion-icon>
              </div>
              <!-- <ion-icon *ngIf="!group.isGrouped && SideBarActive" name="chevron-forward-outline"
                class="text-base noHover "></ion-icon>
              <ion-icon *ngIf="group.isGrouped && SideBarActive" name="chevron-down-outline"
                [ngClass]="{ 'rotate-180': displayAccordionSection == i, 'rotate-0': displayAccordionSection !== i }"
                class="text-base  transition-transform duration-200 transform noHover">
              </ion-icon> -->
            </div>
          </div>
        </ng-template>

      </div>
      <!-- Cerrar sesion -->
      <div class="h-14 cursor-pointer z-50">
        <div class="relative h-14 z-9">
          <span class="division self-center mx-auto"
            [ngClass]="SideBarActive ? 'division-nav-inactive' : 'division-nav-active'"></span>
          <div class="mt-4  group transition duration-300 flex items-center side-item  absolute top-0 right-0 w-full h-full text-white"
            [ngClass]="{
              'justify-start' : SideBarActive,
              'justify-center hover:w-84 inactive-menu-item' : !SideBarActive,
            }"
            (click)="confirmarSalir()">
            <div class="flex h-full  items-center" [ngClass]="SideBarActive ? 'hover-path mx-5 px-6 w-full' : ''">
              <ion-icon class="text-xl" src="assets/icon/logout.svg"
                [ngClass]="SideBarActive ? 'mr-4' : 'group-hover:mr-2'"></ion-icon>
              <span class="whitespace-nowrap z-auto  text-base font-medium"
                [ngClass]="SideBarActive ? 'block' : 'hidden group-hover:block'">Cerrar sesión</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
