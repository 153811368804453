import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicModule } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from "../environments/environment";
import { ComponentsModule } from './components/components.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "./service/interceptors/interceptor.service";
import { IonicStorageModule } from '@ionic/storage-angular';
import { MatIconModule } from "@angular/material/icon";

import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
// import { IonicSelectableModule } from "ionic-selectable";
import { IonicSelectableComponent, IonicSelectableAddItemTemplateDirective } from 'ionic-selectable';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import {IStepOption, TourAnchorMatMenuDirective, TourMatMenuModule, TourService} from 'ngx-ui-tour-md-menu';
import {RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
// import {MatCardModule} from '@angular/material/card';
import { NgChartsModule  } from 'ng2-charts';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { JoyrideModule } from "ngx-joyride";
import { LottieModule, provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginatorIntl } from './components/custom-paginator-intl';
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    IonicModule.forRoot({
      innerHTMLTemplatesEnabled: true,
    }),
    IonicStorageModule.forRoot(),
    JoyrideModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    HttpClientModule,
    ComponentsModule,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    // MatCardModule,
    NgChartsModule,
    IonicSelectableComponent,
    IonicSelectableAddItemTemplateDirective,
    NgxExtendedPdfViewerModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    // IonicSelectableModule,
    {
			provide: HTTP_INTERCEPTORS,
			useClass: InterceptorService,
			multi: true,
		},
    SQLite,
    File,
		HTTP,
		AndroidPermissions,
    provideAnimations(),
    InAppBrowser,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
