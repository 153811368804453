<canvas id="canvas" [ngClass]="{'hidden':finish, 'z-[-1]':isIphoneDevice, 'z-0' : isIpadDevice}" [ngStyle]="{'background-color': dataProcess.color_map_background ? dataProcess.color_map_background : '#65A3EE' }">

</canvas>

<div class="absolute w-full h-full flex flex-col-reverse items-center" id="elementHtml" [ngClass]="{'hidden':finish}">
  <div class="w-full h-full flex flex-col-reverse items-center" [id]="'stage-' + idx" *ngFor="let stage of dataProcess.stages; let idx = index">
    <div class="content-stage border-1 border-primary rounded-lg py-3 px-6 flex flex-col gap-3.5 absolute" [id]="'title-' + idx" [ngClass]="{'hidden':stage.status=='locked'}" [ngStyle]="{'border-color': dataProcess.color}">
      <span class="text-primary text-xl font-semibold text-center leading-5" [ngStyle]="{'color': dataProcess.color}">{{stage.title}}</span>
      <span class="text-primary text-base font-normal text-center" [ngStyle]="{'color': dataProcess.color}">Duración: {{stage.duration}}</span>
      <div class="absolute bottom-2 right-2 rounded-full bg-primary">
        <img [src]="brandLogo" class="w-5 h-5 rounded-full" />
      </div>
    </div>
    <div *ngIf="stage.status === 'locked' || !dataProcess.certificate?.enabled" class="stageLocked">

        <ion-icon [src]="'assets/img/ind_locked_left.svg'" class="stageLockedLeft"></ion-icon>
        <ion-icon [src]="'assets/img/ind_locked_right.svg'" class="stageLockedRight"></ion-icon>

    </div>
    <div class="flex flex-col-reverse items-center" [ngClass]="{'hidden':stage.status=='locked'}">
      <div (click) = "mostrarModal(activity)" class="icon_activity cursor-pointer absolute rounded-full border-1 border-primary flex justify-center items-center activity{{idx+1}} " [ngClass]="{ 'select-activity': activity_selected.id == activity.id }" *ngFor="let activity of stage.activities; let idA = index" [id]="idA" [ngStyle]="{'background-color': activity.status === 'finished' ? dataProcess.color : '#fff', 'border-color': dataProcess.color}">
        <div
          [id]="'tooltip-' + idA"
          (mouseenter)="onMouseEnterTooltip(idA, activity)"
          [attr.data-tooltip]="activity.title"
          class="tooltip-tag no-wrap-chip text-white font-medium text-xs rounded-lgmax px-3 py-1 flex justify-center items-center"
          >
          <mat-icon class="mdi text-xl flex justify-center items-center rounded-full" [ngClass]="iconActivity(activity.type.code)" [ngStyle]="{'background-color': activity.status === 'finished' ? dataProcess.color : '#fff' , 'color': activity.status === 'finished' ? '#fff' : dataProcess.color}"></mat-icon>
          <div class="absolute rounded-full state-activity bg-white flex justify-center items-center h-3.5 w-3.5" *ngIf="['finished','locked'].includes(activity.status)">
            <mat-icon class="mdi text-xxs flex justify-center items-center rounded-full" [ngClass]="activity.status == 'finished' ? 'mdi-check-bold' : activity.status == 'locked' ? 'mdi-lock': ''" [ngStyle]="{'color': dataProcess.color}"></mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="absolute w-full flex flex-col gap-14 justify-center items-center" id="elementHtml2" [ngStyle]="{'background-color': finish ? dataProcess.color : ''}" [ngClass]="{'h-full md:h-11/12':finish,'hidden': !dataProcess.certificate?.enabled}">
  <div class="content-certificate" [ngClass]="{'absolute':!finish}">
    <!-- <div class="rounded-full border-1 border-green-600 p-6" (click)="finish = true" [ngStyle]="{'border-color': dataProcess.color}" *ngIf="!finish"> -->
      <div  *ngIf="!finish" (click)="finish = true" class="rounded-full bg-primary p-7 certified-animated-btn cursor-pointer" [ngStyle]="{'background-color': dataProcess.color}">
        <div class="rounded-full bg-primary overflow_hidden flex items-center justify-center w-40 h-40" [ngStyle]="{'background-color': dataProcess.color}">
          <img src="{{ dataProcess.icon_finished ? ruta_imagen + dataProcess.icon_finished : '../../../assets/img-ind/icon-finish.png' }}" class="w-full h-full rounded-full" [ngClass]="dataProcess.icon_finished ? 'object-fill' : 'object-none'"/>
        </div>
      </div>
    <!-- </div> -->
    <div *ngIf="finish" class="rounded-full overflow_hidden flex items-center justify-center" >
      <img src="{{ dataProcess.icon_finished ? ruta_imagen + dataProcess.icon_finished : '../../../assets/img-ind/icon-finish.png' }}" class="w-48 h-auto"/>
    </div>
    <div *ngIf="finish" class="flex flex-col gap-16 w-60 mb-20">
      <p class="text-white text-3xl font-bold text-center">
        {{dataProcess.certificate?.message}}
      </p>
      <div class="flex flex-col gap-8">
        <ion-button *ngIf="dataProcess.certificate?.login_aprendizaje" mode="md" class="ind-complete-btn text-base font-semibold text-white border-white normal-case" (click)="confirmarSalir()">
          Seguir aprendiendo
        </ion-button>
        <ion-button *ngIf="dataProcess.certificate?.url"  mode="md" class="ind-complete-btn text-base font-semibold text-white border-white normal-case" (click)="redirectCertificate(dataProcess.certificate?.url, dataProcess.certificate?.url_download)">
          Ver certificado
        </ion-button>
      </div>
    </div>
  </div>
</div>
