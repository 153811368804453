import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RewardsService } from 'src/app/service/rewards/rewards.service';
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: 'app-card-anuncios',
  templateUrl: './card-anuncios.component.html',
  styleUrls: ['./card-anuncios.component.scss'],
})
export class CardAnunciosComponent  implements OnInit {
  @Input() mediaType: string;
  @Input() cardChipLeftIsActive: boolean = false;
  @Input() smScrollIsActive: boolean = false;
  @Input() cardMediaUrl: string;
  @Input() customClass: string;
  // @Input() audioUrl: string;
  @Input() audioAutoplay: boolean = false;
  @Input() applyBlur: boolean = false;
  @Input() applyScale: boolean = false;
  @Output() multimediaClick: EventEmitter<any> = new EventEmitter<any>();
  audioPlayerIsActive:boolean = false;
  @Input() coinCustomImage;
  coinOptions: AnimationOptions = {
    path: '/assets/animations/coin.json',
    loop: true,
    autoplay: true
  };

  constructor(public rewardsService: RewardsService,) { }

  ngOnInit() {
    // console.log(this.mediaType);
    this.getRewardConfig()
  }

  onClick() {
      this.multimediaClick.emit();
  }

  playAudio() {
    this.audioPlayerIsActive = true;
  }

  getRewardConfig() {
    // this.coinCustomImage = this.rewardsService.coinCustomImage;
  }

}
