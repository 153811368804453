import { Component, OnInit, Input, Output  } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['../button.component.scss'],
})
export class SecondaryButtonComponent implements OnInit {
  @Input() normalBtn:boolean = false;
  @Input() btnText: String;
  @Input() customClassAppBtn: String; //recibe: class
  @Input() isDisabled:boolean = false;
  @Input() type: string = 'button'; //submit | button | reset

  @Input() addIcon: string | null = null; //left | right
  @Input() iconClass: string = '';

  @Output() btnClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  onClickBtn() {
    this.btnClick.emit();
  }

}
