import { Component, Input, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

interface Option {
  label:string,
  code: string,
}

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent  implements OnInit{
  // dataCompatible: any;
  // optionsList: any = null;

  @Input() tooltipMsg: string | null = null;
  @Input() options:Option[];

  constructor(private navParams: NavParams, private popoverController: PopoverController) { }

  ngOnInit() {
    // this.dataCompatible = this.navParams.get('compatible');
    // this.optionsList = this.navParams.get('options');
  }

  selectOption(option) {
    this.popoverController.dismiss({ selectedOption: option });
  }


}




