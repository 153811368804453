import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-pdf-player',
  templateUrl: './pdf-player.component.html',
  styleUrls: ['./pdf-player.component.scss'],
})
export class PdfPlayerComponent  implements OnInit, OnDestroy {
  @Input() pdfUrl:string = "";
  totalPages: number | null = null;


  constructor() { }

  ngOnInit() {
    console.log("Init...")
  }

  ngOnDestroy() {
    console.log("Destroy...")
  }

  afterLoadComplete(pdf): void {
    this.totalPages = pdf.pagesCount;
    // console.log("total pages: ",pdf);
  }

  pagechanging(e){
    // console.log("page changing", e);
    // e === this.totalPages && media.status_progress !== "revisado"
    if(e === this.totalPages) {
      console.log("pdf completed");
      // localStorage.setItem("pdfComplete", "true")
    }
  }

  onError(err) {
    console.log("*comp error: ",err);
  }


}
