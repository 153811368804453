<ion-toolbar class="lg:hidden mobile-menu-container" >
	<ion-grid class="p-0">
		<ion-row class="text-xxs text-white text-center "  [ngClass]="{ 'ios-safe-area-bottom': platformIsIOS }">
			<ion-col
				*ngFor="let menu of array_menu4"
				(click)="!networkIsActive && menu.path !== 'cursos' ? '' : openPage(menu.path)"
				class="border-b-6 border-solid py-3 flex flex-col items-center justify-center  text-white ion-activatable ripple-parent"
				[ngClass]="{
					'page-selected border-white': select_page == menu.path,
					'border-transparent': select_page != menu.path,
					'opacity-50': !networkIsActive && menu.path !== 'cursos'
				}"
			>
				<ion-ripple-effect></ion-ripple-effect>
				<ion-icon [src]="select_page == menu.path ? menu.selectedIcon : menu.icon" class="text-2xl align-text-center  w-full mb-1.5"></ion-icon>
				<span>{{ menu.name }}</span>
			</ion-col>
			<!-- open side menu-->
			<ion-col
				class="py-3 text-white flex flex-col items-center justify-center border-b-6 border-solid border-transparent  ion-activatable ripple-parent"
				(click)="openSideBarMobile()"
			>
			<ion-ripple-effect></ion-ripple-effect>
				<div class="rounded-full h-6 w-6  border-1 border-white flex justify-center items-center mb-1.5 box-content bg-white">
					<img *ngIf="data_empresa" class="w-5 h-5 rounded-full" [src]="data_empresa.app_main_isotipo || '../../../assets/icon/favicon.png' ">
				</div>
				<span>Menú</span>
				<!-- <span>{{ iniciales || "" }}</span> -->
				<div *ngIf="new_notification" class="flex w-3 h-3 absolute right-4 top-3">
					<div class="relative">
						<div class="w-3 h-3 bg-red-500 rounded-full"></div>
						<span
							class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75 top-0 left-0"
						></span>
					</div>
				</div>

			</ion-col>
		</ion-row>
	</ion-grid>
</ion-toolbar>
