<ng-container *ngIf="!normalBtn">
  <ion-button fill="outline" mode="md" class="app-tertiary-btn text-base font-semibold"
    [ngClass]="customClassAppBtn ? customClassAppBtn : '' " [type]="type" (click)="onClickBtn()" [disabled]="isDisabled"
  >
    <span *ngIf="addIcon && addIcon === 'left'" class="mr-2.5" [ngClass]="iconClass ? iconClass : '' ">
      <ng-content select="[leftIcon]"></ng-content>
    </span>
    {{btnText}}
    <span *ngIf="addIcon && addIcon === 'right'" class="ml-2.5" [ngClass]="iconClass ? iconClass : '' ">
      <ng-content select="[rightIcon]"></ng-content>
    </span>
  </ion-button>
</ng-container>

<ng-container *ngIf="normalBtn">
  <button (click)="onClickBtn()"
  [disabled]="isDisabled"
  [type]="type"
  class="normal-btn bg-white border-[1.5px] border-solid rounded-lg py-2.5 px-4 hover:bg-gray-100  ion-activatable relative overflow-hidden flex items-center justify-center"
  [ngClass]="customClassAppBtn ? customClassAppBtn : 'text-base text-primary border-primary'"
  >
    <ion-ripple-effect></ion-ripple-effect>
    <ng-content ></ng-content>
  </button>
</ng-container>
