import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID  } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterTabComponent } from './footer-tab/footer-tab.component';
import { MyHeaderComponent } from './my-header/my-header.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { IonicModule } from '@ionic/angular';
import { CursosListModalComponent } from './cursos-list-modal/cursos-list-modal.component';
import { SideMenuMobileComponent } from './side-menu-mobile/side-menu-mobile.component';
import { SelectBoxComponent } from './ui/select-box-component/select-box/select-box.component';
import { OptionSelectBoxComponent } from './ui/select-box-component/option-select-box/option-select-box.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { ContenidoMediaComponent } from './contenido-media/contenido-media.component';
import { PopoverLeaveComponent } from './popover-leave/popover-leave.component';
import { NpsModalComponent } from './nps-modal/nps-modal.component';
import { PlatformAvailableComponent } from './platform-modal/platform-available/platform-available.component';
import { PlatformUpgradeAvailableComponent } from './platform-modal/platform-upgrade-available/platform-upgrade-available.component';
import { PopoverComponent } from './popover/popover.component';
import { PopoverInfoComponent } from './popover-info/popover-info.component';
import { BasicModalComponent } from './basic-modal/basic-modal.component';
import { PasantiaModalComponent } from './pasantia-modal/pasantia-modal.component';
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { IconSvgComponent } from './ui/icon-svg/icon-svg.component';
import { PrimaryButtonComponent } from './buttons/primary-button/primary-button.component';
import { TertiaryButtonComponent } from './buttons/tertiary-button/tertiary-button.component';
import { SecondaryButtonComponent } from './buttons/secondary-button/secondary-button.component';
import { NotificationModalComponent } from './notification-modal/notification-modal.component';
import { BasicDrawComponent } from './basic-draw/basic-draw.component';
import { ClickedOutsideModule } from '../directives/clicked-outside.directive';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { ImageSvgComponent } from './ui/image-svg/image-svg.component';
import { EventTrackerModule } from '../directives/event-tracker.directive';
import { MultipleOptionModalComponent } from './multiple-option-modal/multiple-option-modal.component';
import { AutocompleteComponent } from './ui/autocomplete/autocomplete.component';
import { GetSignatureComponent } from './get-signature/get-signature.component';
import { BasicMapComponent } from './basic-map/basic-map.component';
import { SesionCardComponent } from './sesion-card/sesion-card.component';
import { SelectMobileColorOptionsComponent } from './select-mobile-color-options/select-mobile-color-options.component';
import { BasicModalTypeSheetComponent } from './basic-modal-type-sheet/basic-modal-type-sheet.component';
import { PreguntaOpcSimpleComponent } from './pregunta-opc-simple/pregunta-opc-simple.component';

// import { SelectComponent } from './select/select.component';
// import { MdPopoverComponent } from './md-popover/md-popover.component';
// import { SearchBarComponent } from './search-bar/search-bar.component';
// import { NotificationBoxComponent } from './notification-box/notification-box.component';
// import { SelectMobileModalComponent } from './select-mobile-modal/select-mobile-modal.component';
// import { CalendarModalComponent } from './calendar-modal/calendar-modal/calendar-modal.component';
// import { NgCalendarModule  } from 'ionic2-calendar';
// import { CustomChipComponent } from './custom-chip/custom-chip.component';
// import { QuestionComponent } from './question/question.component';
// import { CustomPopoverComponent } from './custom-popover/custom-popover.component';
// import { SelectMobileStandardOptionsComponent } from './select-mobile-standard-options/select-mobile-standard-options.component';
// import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';

import localeEsPe from '@angular/common/locales/es-PE';
import { StepCardComponent } from './step-card/step-card.component';
import { DownloadResourceItemComponent } from './download-resource-item/download-resource-item.component';
import { ChecklistCardComponent } from './checklist-card/checklist-card.component';
import { PipesModule } from '../pipes/pipes.module';
import { PreguntaOpcMultipleComponent } from './pregunta-opc-multiple/pregunta-opc-multiple.component';
import { PreguntaOpcValoracionComponent } from './pregunta-opc-valoracion/pregunta-opc-valoracion.component';
import { PreguntaOpcNpsComponent } from './pregunta-opc-nps/pregunta-opc-nps.component';
import { PreguntaOpcTextoComponent } from './pregunta-opc-texto/pregunta-opc-texto.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { CustomSearchBarComponent } from './custom-search-bar/search-bar.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { CustomMobileSearchbarComponent } from './custom-mobile-searchbar/custom-mobile-searchbar.component';
import { FiltroToogleComponent } from './filtro-toogle/filtro-toogle.component';
import { CurrencyCounterComponent } from './currency-counter/currency-counter.component';
registerLocaleData(localeEsPe, 'es-Pe');
import { LottieModule, } from 'ngx-lottie';
import { RewardsGuideComponent } from './rewards-guide/rewards-guide.component';
import { RewardReceivedModalComponent } from './reward-received-modal/reward-received-modal.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { CardAnunciosComponent } from './card-anuncios/card-anuncios.component';
import { PdfPlayerComponent } from './pdf-player/pdf-player.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { HomeDetailCardComponent } from './home-detail-card/home-detail-card.component';


@NgModule({
  declarations: [
    FooterTabComponent,
    MyHeaderComponent,
    ProgressBarComponent,
    SideMenuComponent,
    CursosListModalComponent,
    SideMenuMobileComponent,
    SelectBoxComponent,
    OptionSelectBoxComponent,
    CountdownTimerComponent,
    ContenidoMediaComponent,
    PopoverLeaveComponent,
    NpsModalComponent,
    PlatformAvailableComponent,
    PlatformUpgradeAvailableComponent,
    PopoverComponent,
    PopoverInfoComponent,
    BasicModalComponent,
    PasantiaModalComponent,
    PopupMenuComponent,
    IconSvgComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TertiaryButtonComponent,
    NotificationModalComponent,
    BasicDrawComponent,
    DropdownComponent,
    TimePickerComponent,
    ImageSvgComponent,
    BasicMapComponent,
    MultipleOptionModalComponent,
    AutocompleteComponent,
    GetSignatureComponent,
    SesionCardComponent,
    SelectMobileColorOptionsComponent,
    BasicModalTypeSheetComponent,
    StepCardComponent,
    DownloadResourceItemComponent,

    ChecklistCardComponent,
    PreguntaOpcSimpleComponent,
    PreguntaOpcMultipleComponent,
    PreguntaOpcValoracionComponent,
    PreguntaOpcNpsComponent,
    PreguntaOpcTextoComponent,
    FilterMenuComponent,
    CustomSearchBarComponent,
    PageTitleComponent,
    CustomMobileSearchbarComponent,
    FiltroToogleComponent,
    CurrencyCounterComponent,
    RewardsGuideComponent,
    RewardReceivedModalComponent,
    VideoPlayerComponent,
    CardAnunciosComponent,
    PdfPlayerComponent,
    HomeDetailCardComponent,
    // SearchBarComponent,
    // SelectComponent,
    // MdPopoverComponent,
    // NotificationBoxComponent,
    // CalendarModalComponent,
    // ToastComponent,
    // SelectMobileModalComponent,
    // SelectMobileModalComponent,
    // CustomChipComponent,
    // QuestionComponent,
    // CustomPopoverComponent,
    // SelectMobileStandardOptionsComponent,
    // CustomTooltipComponent,
  ],
  exports: [
    FooterTabComponent,
    MyHeaderComponent,
    ProgressBarComponent,
    SideMenuComponent,
    CursosListModalComponent,
    SideMenuMobileComponent,
    SelectBoxComponent,
    OptionSelectBoxComponent,
    CountdownTimerComponent,
    ContenidoMediaComponent,
    PopoverLeaveComponent,
    NpsModalComponent,
    PlatformAvailableComponent,
    PlatformUpgradeAvailableComponent,
    PopoverComponent,
    PopoverInfoComponent,
    BasicModalComponent,
    PasantiaModalComponent,
    PopupMenuComponent,
    IconSvgComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TertiaryButtonComponent,
    NotificationModalComponent,
    BasicDrawComponent,
    DropdownComponent,
    TimePickerComponent,
    ImageSvgComponent,
    BasicMapComponent,
    MultipleOptionModalComponent,
    AutocompleteComponent,
    GetSignatureComponent,
    SesionCardComponent,
    SelectMobileColorOptionsComponent,
    BasicModalTypeSheetComponent,
    StepCardComponent,
    DownloadResourceItemComponent,
    ChecklistCardComponent,
    PreguntaOpcSimpleComponent,
    PreguntaOpcMultipleComponent,
    PreguntaOpcValoracionComponent,
    PreguntaOpcNpsComponent,
    PreguntaOpcTextoComponent,
    FilterMenuComponent,
    CustomSearchBarComponent,
    PageTitleComponent,
    CustomMobileSearchbarComponent,
    FiltroToogleComponent,
    CurrencyCounterComponent,
    RewardsGuideComponent,
    RewardReceivedModalComponent,
    VideoPlayerComponent,
    CardAnunciosComponent,
    PdfPlayerComponent,
    HomeDetailCardComponent,
    // SearchBarComponent,
    // SelectComponent,
    // MdPopoverComponent,
    // NotificationBoxComponent,
    // CalendarModalComponent,
    // ToastComponent,
    // SelectMobileModalComponent,
    // SelectMobileModalComponent,
    // CustomChipComponent,
    // QuestionComponent,
    // CustomPopoverComponent,
    // SelectMobileStandardOptionsComponent,
    // CustomTooltipComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ClickedOutsideModule,
    EventTrackerModule,
    PipesModule,
    LottieModule,
    NgxExtendedPdfViewerModule,
    // NgCalendarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }
