
import { Injectable } from "@angular/core";
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from "@angular/common/http";
@Injectable({
	providedIn: "root",
})
export class ApiService {
	private lista_preguntas: any[] = [];
	private lista_encuesta: any[] = [];
	private _change_cursos: boolean = false;
	private _codigo_reunion: string = "";

	//API URLs
	apiUrls = {
		serviceBase : environment.SERVICE_BASE,
		serviceBaseGestor : environment.SERVICE_BASE_GESTOR,
		serviceBaseRest : environment.SERVICE_BASE_REST,
		serviceBaseAuth : environment.SERVICE_BASE_AUTH,
		urlBase : environment.URL_BASE,
		urlReporte : environment.URL_REPORTE,
		urlAndroid: environment.APP_PLAYSTORE,
		urliOS: environment.APP_ID_IOS,
	};

	constructor(public http: HttpClient) {
		//console.log("api service", this.apiUrls)
		if(environment.APP_MULTIMARCA && (this.apiUrls.serviceBase === "" || !this.apiUrls.serviceBase)) {
			const apiUrlForBrand = localStorage.getItem("api_urls");
			if(apiUrlForBrand) {
				this.apiUrls = JSON.parse(apiUrlForBrand);
			}
		}
	}

	// Setters & Getters
	public get getApiUrls() {
		return this.apiUrls;
	}
	public set setApiUrls(newUrls){
		this.apiUrls = newUrls;
	}

	public get change_cursos(): boolean {
		return this._change_cursos;
	}
	public set change_cursos(value: boolean) {
		this._change_cursos = value;
	}

	public get preguntas(): any {
		return this.lista_preguntas;
	}
	public get encuestas(): any {
		return this.lista_encuesta;
	}

	public get codigo_reunion(): string {
		return this._codigo_reunion;
	}
	public set codigo_reunion(value:string){
		this._codigo_reunion = value;
	}
	/***************  MULTIMARCA - PETICIÓN A MASTER *******************/
	getBrandApiConnections (user, password, ver_usu, ver_os, captchaToken) {

		let url: string = environment.URL_MASTER + "/api/login";
		let body = {
			user,
			password,
			os: ver_os,
			version: ver_usu,
			"g-recaptcha-response": captchaToken,
		}
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	getBrandApiConnectionsChangePassword (email) {
		let url: string = environment.URL_MASTER + "/api/recuperar_password?email=" + email;
		let promesa = this.http
		.get(url)
		.toPromise()
		.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
		.catch((error) => Promise.reject(error));
		return promesa;
	}


	/********************* AUTH - LOGIN *************************/

	/*impersonation - gestor-app */
	loginImpersonationSession(token, expires, signature, UrlBase, isAzure) {
		let url:string = "";
		if(environment.APP_MULTIMARCA) {
			 url = UrlBase + `/api/auth/login/external/${token}`;
		} else {
			url = this.apiUrls.serviceBaseAuth + `login/external/${token}`;
		}

		let params = new HttpParams();
    if(isAzure) {
      params = params.append("azure",isAzure);
    }
		params = params.append("expires",expires);
		params = params.append("signature",signature);

    console.log(url);

		return this.http
			.post(url, {}, {
				params
			})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}
	/*** Login **/
	getConfig () {
		let url: string = this.apiUrls.serviceBase + "config_ambiente";
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	login(usuario: string = null, pass: string = null, ver_usu: string, ver_os: string, captchaToken) {
		let body = {
			user:usuario,
			password:pass,
			os: ver_os,
			version: ver_usu,
			"g-recaptcha-response": captchaToken,
		}
		let url = this.apiUrls.serviceBaseAuth + "login";
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}
  //login SSO
  validateSSOemail(email) {
		let url: string = environment.URL_MASTER + "/api/verify_email";
		let body = {
			email,
		}
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	// Recuperar contraseña - Envío de correo
	recuperar_contra(email: string = null) {
		const body = { email };

		let url = this.apiUrls.serviceBase + "password/email";
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}
	// Cambiar contraseña
	cambiar_contra(
		email: string = null,
		password: string = null,
		password_rec: string = null,
		token: string = null,
		os: string = null,
		version: string = null
	) {
		let body = {
			email: email.toString(),
			password,
			password_confirmation: password_rec,
			token,
			os,
			version,
		};
		let url = this.apiUrls.serviceBase + "password/reset";
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}
	//APIs seguridad
	updateSecurePassword(
		email: string = null,
		document: string = null,
		password: string = null,
		password_confirmation: string = null,
		token: string = null,
		os: string = null,
		version: string = null
	) {
		let body:any = {
			password,
			password_confirmation,
			token,
			os,
			version,
		};

		email ? body= {...body, email} : body= {...body, document};

		let url = this.apiUrls.serviceBase + "reset";
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}
	updatePasswordVoluntarily(formValue) {
		let body:any = {
			oldPassword:formValue.actualPassword,
			newPassword:formValue.newPassword,
			newPasswordConfirmation:formValue.confirmNewPassword,
		};
		let url = this.apiUrls.serviceBase + "users/password/reset";
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	identityValidation(birthdate: string = null, gender: string = null, id_user) {
		let body: any = {
			id_user,
		};

		if(birthdate && !gender){
			body= {...body, birthday_date: birthdate.toString()} ;
		} else if(birthdate && gender) {
			body= {...body, birthday_date: birthdate.toString(), gender: gender} ;
		}

		let url = this.apiUrls.serviceBase + "quizz";
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	/*** Función para cerrar sesión */
	// async logout() {
	// 	await this.logout_token()
	// 		.then((res) => {
	// 			this.up.clearStorage();
	// 			// this.stopRefreshToken();
	// 		})
	// 		.catch((e) => {
	// 			console.log(e.status, e.url);
	// 		});
	// }

	/* Request al API para revocar el token */
	logout_token() {
		let url = this.apiUrls.serviceBaseAuth + "logout";

		let body = {
			token: localStorage.getItem("token"),
		};

		return this.http
			.post(url, JSON.stringify({ body }))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	/**
	 * Función para refrescar token
	 */
	// refreshToken() {
	// 	let url = this.ruta_auth + "refresh";
	// 	return this.http
	// 		.post(url, {})
	// 		.toPromise()
	// 		.then((res) => Promise.resolve(JSON.parse(JSON.stringify(res))))
	// 		.catch((err) => Promise.reject(err));
	// }

	// private refreshTokenTimeout;

	// startRefreskToken(expires_tk: number) {
	// const expires = expires_tk * 1000;
	// console.log(expires);
	// const timeout = expires - (20 * 1000);
	// console.log(timeout);
	// this.refreshTokenTimeout = setTimeout( () => {
	// 	this.refreshToken().then( res => {
	// 		console.log(res);
	// 		localStorage.setItem("token", res.access_token);
	// 		this.startRefreskToken(res.expires_in);
	// 	})
	// }, timeout);

	// 	console.log("Temporalmente no está activo refrescar token...");
	// }

	// stopRefreshToken() {
	// 	clearTimeout(this.refreshTokenTimeout);
	// }

	guardar_token_fcm(token: any) {
		let url: string = this.apiUrls.serviceBaseRest + "guardar_token_firebase";
		console.log(url);

		let body = {
			fcm_token: token.toString(),
		};

		let promesa = this.http
			.post(url, JSON.stringify(body))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	getEmailValidation(email) {
		let url = `${this.apiUrls.serviceBaseRest}existe_email/${email}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	guardarSoporteLogin(form) {
		let url: string = this.apiUrls.serviceBaseRest + "registrar_soporte_login";

		let body = {
			dni: form.documento,
			phone: form.celular,
			email: form.email,
			details: form.detalle,
		};

		let promesa = this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}


	userValidation(form) {
		let url: string = environment.URL_MASTER + "/api/registrar_soporte_login";

		let body = {
			dni: form.documento,
			phone: form.celular,
			email: form.email,
			details: form.detalle,
		};

		let promesa = this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}


	listarEmpresasLogin() {
		let url: string = this.apiUrls.serviceBaseRest + "listar_empresas";
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* / AUTH - LOGIN *************************/

	/********************* ANUNCIOS  *************************/
	cargar_anuncios() {
		let url: string = this.apiUrls.serviceBaseRest + "announcements";
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	showModalOrder() {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBase + "notifications")
				.toPromise()
				.then((res) => {
				    // console.log(res);
				   resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}




	/********************* / ANUNCIOS  *************************/

	/*********************  CURSOS - TEMAS *************************/

	// get Escuelas
	cargarEscuelas() {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + "cursos")
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	descargarVimeo(vimeo_code) {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + `vimeo/get_download_link_vimeo/${vimeo_code}`)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	/********************* / CURSOS - TEMAS *************************/

	/********************* EVALUACIONES  *************************/

	// Evaluacion
	// cargarEvaluacion(tema_id) {
	// 	return new Promise((resolve, reject) => {
	// 		this.http
	// 			.get(this.apiUrls.serviceBaseRest + "cargar_preguntas/" + tema_id)
	// 			.toPromise()
	// 			.then((res) => {
	// 				resolve(res);
	// 			})
	// 			.catch((err) => {
	// 				reject(err);
	// 			});
	// 	});
	// }
	cargarEvaluacion(tema_id, userDate?, is_offline = false) {
		let url = is_offline ? 	`?user_datetime=${userDate}&is_offline=${is_offline}` : `?user_datetime=${userDate}`
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + "cargar_preguntas/" + tema_id + `${url}`)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	// Enviar evaluacion
	enviarEvaluacion(params) {
		return new Promise((resolve, reject) => {
			this.http
				.post(this.apiUrls.serviceBaseRest + "evaluar_preguntas", params)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
	// Enviar evaluacion scorm
	saveScormEvaluation(params) {
		return new Promise((resolve, reject) => {
			this.http
				.post(this.apiUrls.serviceBaseRest + "save_scorm_evaluation", params)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

  validateEvaluation(identifier, course_id, topic_id, brand_url) {
    let url = `?identifier=${identifier}&course_id=${course_id}&topic_id=${topic_id}`
		return new Promise((resolve, reject) => {
			this.http
      .get(brand_url +"/api/rest/validate-info-quiz" + url)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
  }
	// Enviar Intento
	// Fuente siempre sera app
	// evaluacionIntento(post_id) {
	// 	return new Promise((resolve, reject) => {
	// 		this.http
	// 			.get(this.apiUrls.serviceBaseRest + `preguntasintentos_v7/${post_id}/app`)
	// 			.toPromise()
	// 			.then((res) => {
	// 				resolve(res);
	// 			})
	// 			.catch((err) => {
	// 				reject(err);
	// 			});
	// 	});
	// }

	// Revisar Tema
	revisarTema(tema_id) {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + `temas/actividad_tema_revisado/${tema_id}`)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	// Consultar respuestas de usuario por tema
	usuario_respuestas_eval(post_id: number) {
		let url: string = this.apiUrls.serviceBaseRest + "usuario_respuestas_eval/" + post_id;

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	// Carga las preguntas de un determinado post (evaluación)
	async preguntas_rptas_usuario(id: number = null) {
		if (id != null) {
			let url: string = this.apiUrls.serviceBaseRest + "preguntas_rptas_usuario/" + id;
			let promesa = await this.http
				.get(url)
				.toPromise()
				.then(async (response) => await Promise.resolve(JSON.parse(JSON.stringify(response))))
				.catch((error) => Promise.reject(error));
			return promesa;
		}
	}

	updateRespuestasAbiertas(post_id: number, user_id: number, rptas: any[] = null) {
		if (post_id != null && user_id != null) {
			let url: string = this.apiUrls.serviceBaseRest + "evaluar_abiertas";
			console.log(url);
			let params = {
				post_id: post_id.toString(),
				user_id: user_id.toString(),
				rptas: encodeURIComponent(JSON.stringify(rptas)),
				fuente: "app",
			};

			let promesa = this.http
				.post(url, JSON.stringify(params))
				.toPromise()
				.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
				.catch((error) => Promise.reject(error));

			return promesa;
		}
	}

	// carga las encuesta de un determinado post  (encuestas)
	cargar_encuestas_curso(curso_id: number = null) {
		if (curso_id != null) {
			let url: string = this.apiUrls.serviceBaseRest + "cursos/cargar_encuestas_curso/" + curso_id;
			// console.log(url);
			let promesa = this.http
				.get(url)
				.toPromise()
				.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
				.catch((error) => Promise.reject(error));
			return promesa;
		}
	}

	// Carga preguntas encuestas libres
	preguntas_enc_libre(enclibre_id: number = null) {
		if (enclibre_id != null) {
			let url: string = this.apiUrls.serviceBaseRest + "preguntas_enc_libre/" + enclibre_id;

			let promesa = this.http
				.get(url)
				.toPromise()
				.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
				.catch((error) => Promise.reject(error));
			return promesa;
		}
	}

	// Guarda las encuestas del post que realizó el usuario - POST
	guardar_encuestas_curso (
		enc_id: number = null,
		user_id: number = null,
		curso_id: number = null,
		data: any[] = null
	) {
		if (enc_id != null || user_id != null) {
			let url: string = this.apiUrls.serviceBaseRest + "cursos/guardar_encuesta_curso_id";

			let curso = null;
			if (curso_id) {
				curso = curso_id.toString();
			}
			let params = {
				enc_id: enc_id.toString(),
				user: user_id.toString(),
				curso: curso,
				data: data,
			};

			// params = params.append('enc_id', enc_id.toString());
			// params = params.append('user', user_id.toString());
			// params = params.append('curso', curso);
			// let dataenc = encodeURIComponent(JSON.stringify(data));
			// params = params.append('data', dataenc);

			let promesa = this.http
				.post(url, JSON.stringify(params))
				.toPromise()
				.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
				.catch((error) => Promise.reject(error));
			return promesa;
		}
	}
	guardar_encuestas_libre(
		enc_id: number = null,
		data: any[] = null
	) {

			let url: string = this.apiUrls.serviceBaseRest + "guardar_enc_libre_id";
			let params = {
				enc_id: enc_id.toString(),
				data: data,
			};

			let promesa = this.http
				.post(url, JSON.stringify(params))
				.toPromise()
				.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
				.catch((error) => Promise.reject(error));
			return promesa;

	}

	/********************* / EVALUACIONES  *************************/

	/********************* FUNCIONES *************************/

	async cargarEncuestasCurso(curso_id: number = null) {
		this.lista_encuesta = [];
		// console.log(curso_id);
		if (curso_id != null) {
			await this.cargar_encuestas_curso(curso_id)
				.then(
					async (response) => {
						if (response.error == 0) console.log(response.data);
						this.lista_encuesta = await response.data.poll.questions;
					},
					(error) => {
						console.log(error);
					}
				)
				.catch((e) => {
					console.log(e.status, e.url);
				});
		}
	}

	async cargarPregEncLibre(enclibre_id: number = null) {
		if (enclibre_id != null) {
			await this.preguntas_enc_libre(enclibre_id)
				.then(
					async (response) => {
						this.lista_encuesta = await response.data.questions;
					}
				)
				.catch((e) => {
					console.log(e.status, e.url);
				});
		}
	}

	// Consultar preguntas segun respuestas de usuario
	async preguntasRptasUsuario(id: number = null) {
		if (id != null) {
			await this.preguntas_rptas_usuario(id)
				.then(
					async (result) => {
						if ((await result.data) != null) {
							let myarray = result.data;
							let currentIndex = myarray.length;
							let temporayValue, randomIndex;

							while (0 !== currentIndex) {
								randomIndex = Math.floor(Math.random() * currentIndex);
								currentIndex -= 1;

								temporayValue = myarray[currentIndex];
								myarray[currentIndex] = myarray[randomIndex];
								myarray[randomIndex] = temporayValue;
							}

							this.lista_preguntas = myarray;
							console.log(this.lista_preguntas);
						}
					},
					(error) => {
						console.log(error);
					}
				)
				.catch((e) => {
					console.log(e.status, e.url);
				});
		}
	}

  getExternalSurveyData(pollId,entityId, UrlBase = null) {
    let url:string = "";
		if(UrlBase) {
			 url = UrlBase + `/api/rest/external-poll/${pollId}/${entityId}`;
		} else {
			url = this.apiUrls.serviceBaseRest + `external-poll/${pollId}/${entityId}`;
		}
    // return this.http.get(url);

    let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
  }

  saveExternalSurveyData(pollId, entityId, body, UrlBase = null) {
    let url:string = "";
		if(UrlBase) {
			 url = UrlBase + `/api/rest/save-external-poll/${pollId}/${entityId}`;
		} else {
			url = this.apiUrls.serviceBaseRest + `save-external-poll/${pollId}/${entityId}`;
		}
    let promesa = this.http
    .post(url, body)
    .toPromise()
    .then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
    .catch((error) => Promise.reject(error));
  return promesa;
  }
	/********************* / FUNCIONES *************************/

	/*********************  PROGRESO *************************/

	// Cargar Progreso
	cargarProgreso() {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + "progreso")
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	cargarProgresoDetalle() {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + "progreso/v2/detail_progress")
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	cargarProgresoEscuelas(tipo) {
		const params = {
			tipo,
		};
		return new Promise((resolve, reject) => {
			this.http
				.post(this.apiUrls.serviceBaseRest + "progreso/v2/schools", JSON.stringify(params))
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	cargarProgresoCursos(school) {
		const params = {
			school,
		};
		return new Promise((resolve, reject) => {
			this.http
				.post(this.apiUrls.serviceBaseRest + "progreso/v2/courses_by_school", JSON.stringify(params))
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	// Cargar cursos de escuela seleccionada
	schoolCourses(escuela_id) {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + "progreso/school/" + escuela_id)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}
// obtener ranking
	cargarRanking() {
		let url = this.apiUrls.serviceBaseRest + "ranking/v2";
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	criterioRanking(code) {
		let url = this.apiUrls.serviceBaseRest + `ranking/criterion-code/${code}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getCurso(curso_id, escuela_id) {
		let url: string = this.apiUrls.serviceBaseRest + `temas/${curso_id}?school_id=${escuela_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getListTemas(curso_id, escuela_id) {
		let url: string = this.apiUrls.serviceBaseRest + `temas/v2/${curso_id}?school_id=${escuela_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	getListCursos(escuela_id) {
		let url: string = this.apiUrls.serviceBaseRest + `temas/${escuela_id}/list-courses`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getTimeIntentos(tema_id) {
		let url: string = this.apiUrls.serviceBaseRest + `contador_tema_reseteo/${tema_id}`;
		let promesa = this.http
			.post(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	// Conteo de visitas en los temas (topics)
	visitas_temas(tema_id) {
		let url: string = this.apiUrls.serviceBaseRest + `upd_reproducciones/${tema_id}`;
		let promesa = this.http
			.post(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	sendSignatureMethod(canvaImgData, courseID) {
		let url: string = this.apiUrls.serviceBaseRest + "cursos/registro-capacitacion";
		const token: string = localStorage.getItem("token");

		//Create form data
		const formData = new FormData();
		formData.append("signature",canvaImgData);
		formData.append("course_id",courseID);

		//POST request using fetch() because http request doesn´t work
		let promesa = fetch(url, {
			// Adding method type
			method: "POST",
			// Adding body or contents to send
			body: formData,
			// Adding headers to the request
			headers: {
				'Authorization':`Bearer ${token}`,
				'Accept':`application/json, */*`
			}
		})
		.then( res => Promise.resolve(res.json()) )
		.catch(error => Promise.reject(error));

		return promesa;
	}


	//Diplomas
	aceptarDiploma(courseId) {
		let url: string = this.apiUrls.serviceBaseRest + `cursos/aceptar-diploma/${courseId}`;
		let promesa = this.http
			.post(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	lista_diplomas(palabra?, status?, schoolId?){
		let text_palabra = "";
		let text_status = "";
		let text_schoolID = "";
		if (palabra) text_palabra = palabra;
		if (status) text_status = `&type=${status}`;
		if (schoolId) text_schoolID = `&schoolId=${schoolId}`;

		let url: string = this.apiUrls.serviceBaseRest +
		`cursos/diplomas?q=${text_palabra + text_status  + text_schoolID}`;

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* / PROGRESO *************************/

	/*********************  REPORTES *************************/
	CargaInicial() {
		let url: string = this.apiUrls.serviceBaseRest + `reportes-supervisores/init`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	CargarEscuelasParaReportes(workspaceId, userID) {
		let url: string = this.apiUrls.urlReporte + `filtros/schools/${workspaceId}/${userID}`;

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	// CargarCursos(schoolsID, form) {
	// 	 let url: string = this.apiUrls.urlReporte + `filtros/schools/courses`;

	// 	 const params = {
	// 		schoolsIds: schoolsID,
	// 		// CursosActivos: form.usersStatus === "activos" || form.usersStatus === "todos" || form.usersStatus === ""  ? true : false,
	// 		// CursosInactivos: form.usersStatus === "inactivos" || form.usersStatus === "todos" ? true : false,
	// 	 };

	// 	let promesa = this.http
	// 		.post(url, JSON.stringify(params))
	// 		.toPromise()
	// 		.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
	// 		.catch((error) => Promise.reject(error));
	// 	return promesa;
	// }

	CargarCursos(schoolsID, form) {
		const url: string = this.apiUrls.urlReporte + `filtros/courses/${schoolsID}/all`;
	    const promesa = this.http
	    .get(url)
	   	.toPromise()
	   	.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
	   	.catch((error) => Promise.reject(error));
	   return promesa;
   }

	NotasXcurso(workspaceId, supervisorId, form ) {
		console.log("form cursos",form);

	    const url: string = this.apiUrls.urlReporte + `exportar/supervisores_notas`;

	   const params = {
		workspaceId: workspaceId,
		supervisorId: supervisorId,
		escuelas: form.escuelas,
		cursos: form.cursos,
		// CursosActivos: form.usersStatus === "activos" || form.usersStatus === "todos" || form.usersStatus === ""  ? true : false,
		// CursosInactivos: form.usersStatus === "inactivos" || form.usersStatus === "todos" ? true : false,
		aprobados: form.resultado === "aprobado" || form.resultado === "" ? true : false,
		desaprobados: form.resultado === "desaprobado" || form.resultado === "" ? true : false,
		desarrollo: form.resultado === "desarrollo" || form.resultado === "" ? true : false,
		encuestaPendiente: form.resultado === "enc_pend" || form.resultado === "" ? true : false,
		noIniciado: form.resultado === "no_iniciado" || form.resultado === "" ? true : false
	   };

		let promesa = this.http
		.post(url, JSON.stringify(params))
		.toPromise()
		.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
		.catch((error) => Promise.reject(error));
		return promesa;
	}

	CargarTemas(coursesIds) {
		let url: string = this.apiUrls.urlReporte + `filtros/topics/${coursesIds}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	NotasXtemas(workspaceId, supervisorId, form ) {
		console.log("form temas",form);
	    const url: string = this.apiUrls.urlReporte + `exportar/supervisores_notas_temas`;
	   const body = {
		workspaceId: workspaceId,
		supervisorId: supervisorId,
		escuelas: form.escuelas,
		cursos: form.cursos,
		temas: form.temas,
		// CursosActivos: form.usersStatus === "activos"  || form.usersStatus === "todos" || form.usersStatus === "" ? true : false,
		// CursosInactivos: form.usersStatus === "inactivos"  || form.usersStatus === "todos" ? true : false,
		revisados: form.resultado === "revisado" || form.resultado === "" ? true : false,
		realizados: form.resultado === "realizado" || form.resultado === "" ? true : false,
		porIniciar: form.resultado === "por-iniciar" || form.resultado === "" ? true : false,
		aprobados: form.resultado === "aprobado" || form.resultado === "" ? true : false,
		desaprobados: form.resultado === "desaprobado" || form.resultado === "" ? true : false,
	   };

		let promesa = this.http
		.post(url, JSON.stringify(body))
		.toPromise()
		.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
		.catch((error) => Promise.reject(error));
		return promesa;
	}

	AvanceUsuarios(workspaceId:number, supervisorId:number ) {
		const url: string = this.apiUrls.urlReporte + `exportar/supervisores_avance_curricula`;

		const params = {
			workspaceId: workspaceId,
			supervisorId: supervisorId
		};

		let promesa = this.http
		.post(url, JSON.stringify(params))
		.toPromise()
		.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
		.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* / REPORTES *************************/

	/*********************  CHECKLIST *************************/
	obtenerAlumnos(alumno?, page?, estado?) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/alumnos`;
		console.log(url);
		let params;
		if(page){
			params = {
				filtro:{
					usuario: alumno,
					estado: estado,
				},
				page: page
			};
		} else{
			params = {
				filtro: alumno,
			};
		}

		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerChecklists(checklist?, page?) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/trainer/checklists`;
		let params;
		if(page){
			params = {
				filtro: {
					nombre: checklist
				},
				page: page
			};
		} else{
			params = {
				filtro: {
					nombre: checklist
				},
			};
		}
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerAlumnosChecklist(checklist_id?, page?) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/checklist/students`;
		let params;
		if(page){
			params = {
				page: page,
				checklist_id: checklist_id
			};
		} else{
			params = {
				checklist_id: checklist_id
			};
		}
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerActividadesChecklist(checklist_id?) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/checklist/${checklist_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	cambiarEstadoActividad(actividades, checklist_id, alumnos_id, alumnos_todos, tipo, feedback_entrador) {
			let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/marcar_actividad`;
			console.log(url);

			let params = {
				actividades,
				checklist_id,
				alumnos_id,
				alumnos_todos,
				tipo,
				feedback_entrador
			};

			let promesa = this.http
				.post(url, JSON.stringify(params))
				.toPromise()
				.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
				.catch((error) => Promise.reject(error));
			return promesa;
		}

	obtenerAlumno(user_id) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/checklist_alumnos/${user_id}`;
		//console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}


	obtenerChecklistsV3(checklist?, page?, lat?, long?, search?) {
		let ifchecklist = checklist ? checklist : '';
		let ifsearch = search ? search : '';
		let coords = '';
		if(lat && long) {
			coords = `&lat=${lat}&long=${long}`;
		}
		let filter = '?filter='+ifchecklist+'&search='+ifsearch
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists` + filter + coords;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	getDataProgressChecklist(typeChecklist) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklists/progress?modality=${typeChecklist}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getSelectedChecklistProgress(isTypeQualifyEntity, id) {
    let url: string = this.apiUrls.serviceBaseRest;
    if(isTypeQualifyEntity) {
      url += `entrenamiento/v2/checklists/progress-instance?audit_id=${id}`;
    } else {
      url += `entrenamiento/v2/checklists/progress-instance?checklist_id=${id}`;
    }
		// let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklists/progress`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getfiltersProgressChecklist() {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklists/progress-init-data`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	obtenerListadoAsignadosChecklist(checklist_id, search_text = '', status= '', page = 1) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists/${checklist_id}/users?search=${search_text}&filter=${status}&page=${page}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	crearNuevaInstanciaChecklist(checklist, data) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists/${checklist}/create-new-instance`;
		let promesa = this.http
			.post(url, JSON.stringify(data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	obtenerListadoEntidadesChecklist(checklist_id, search_text = '', page = 1) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists/${checklist_id}/list-entities?search=${search_text}&page=${page}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	revisarActividadChecklist(checklist, data, action_request, entity_id?) {
		entity_id = entity_id ? entity_id : '';
    action_request = action_request ? action_request : '';
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists/${checklist}/save_activity?action_request=${action_request}&entity_id=${entity_id}`;
		let promesa = this.http
			.post(url, JSON.stringify(data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	guardarPlanAccion(checklist, data, entity_id?) {
		entity_id = entity_id ? entity_id : '';
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklist/${checklist}/save_action_plan?entity_id=${entity_id}`;
		let promesa = this.http
			.post(url, JSON.stringify(data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerChecklistAndActivitiesV3(checklist_id, area_id?, user_id?, entity_id?, is_multiple = false) {
		area_id = area_id ? JSON.stringify(area_id) : '';
		user_id = user_id ? user_id : '';
		entity_id = entity_id ? entity_id : '';
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists/${checklist_id}/activities?theme_id=${area_id}&user_id=${user_id}&entity_id=${entity_id}&multiple=${is_multiple}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	obtenerResultadosChecklist(checklist_id, data) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists/${checklist_id}/results`;
		let promesa = this.http
			.post(url, JSON.stringify(data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	sendMailChecklist(checklist_id, data) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists/${checklist_id}/send-results`;
		let promesa = this.http
			.post(url, JSON.stringify(data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	obtenerListaTemasYAreas(checklist_id, search?, user_id?, entity_id?) {
		search = search ? search : '';
		user_id = user_id ? user_id : '';
		entity_id = entity_id ? entity_id : '';
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklist/${checklist_id}/list-themes?user_id=${user_id}&entity_id=${entity_id}&search=${search}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	verificarVistaComputacional(activity, data_image, entityID, usersSelectedList) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/activity/${activity}/verify_photo?entity_id=${entityID}`;
		let promesa = this.http
			.post(url, JSON.stringify({
				'upload_image': data_image,
        user_ids: usersSelectedList
			}))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	finalizarChecklist(checklist, activity) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklist/${checklist}/save_activities`;
		let promesa = this.http
			.post(url, JSON.stringify(activity))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	guardarFirmaSupervisado(checklist, data, user_id?, entity_id?) {
		user_id = user_id ? user_id : '';
		entity_id = entity_id ? entity_id : '';
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklist/${checklist}/save_signature_supervised?user_id=${user_id}&entity_id=${entity_id}`;
		let promesa = this.http
			.post(url, JSON.stringify(data))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getListSupervised(checklist, entity_id, page, search?) {
    search = search ? search : ''
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklist/${checklist}/${entity_id}/list-supervised?page=${page}&search=${search}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;

	}
	requiredGeolocalizationChecklistsV3() {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/init-data `;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* / CHECKLIST *************************/

	/*********************  VIDEOTECA *************************/

	obtenerCategorias() {
		let url: string = this.apiUrls.serviceBaseRest + `videoteca/get-selects`;
		console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerVideoteca(palabra?, cat_id?, num_page?) {
		let text_cat = "";
		let text_palabra = "";
		let text_num_page = "";

		if (palabra) text_palabra = palabra;
		if (cat_id) text_cat = `&categoria_id=${cat_id}`;
		if (num_page) text_num_page = `&page=${num_page}`;

		let url: string =
			this.apiUrls.serviceBaseRest +
			`videoteca/search?q=${text_palabra + text_cat + text_num_page}`;
		console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerMedia(id_videoteca) {
		let url: string = this.apiUrls.serviceBaseRest + `videoteca/show/${id_videoteca}`;
		console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	agregarVisita(id_videoteca) {
		let url: string = this.apiUrls.serviceBaseRest + `videoteca/store-visit/${id_videoteca}`;
		console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerRelacionados(id_videoteca, page) {
		let text_num_page = "";
		if (page) text_num_page = `?page=${page}`;

		let url: string =
			this.apiUrls.serviceBaseRest + `videoteca/related/${id_videoteca + text_num_page}`;
		console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerRelacionadosXtag(video_actual, tag_id, page) {
		let text_num_page = "";
		if (page) text_num_page = `&page=${page}`;

		let url: string =
			this.apiUrls.serviceBaseRest +
			`videoteca/search?no_id=${video_actual}&tag=${tag_id + text_num_page}`;
		console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* / VIDEOTECA *************************/

	/********************* GLOSARIO *************************/

	//Carga el glosario
	cargar_glosario(lab_id, cat_id, prin_act, palabra, page) {
		let text_act = "";
		let text_cat = "";
		let text_lab = "";
		let text_page = "";
		let index = 0;

		if (prin_act) {
			prin_act.forEach((e) => {
				text_act = text_act + "&principios_activos[" + index + "]=" + e.id;
				index++;
			});
		}
		if (lab_id) text_lab = "&laboratorio_id=" + lab_id;
		if (cat_id) text_cat = "&categoria_id=" + cat_id;
		if (page) text_page = "&page=" + page;
		let token = localStorage.getItem("token");
		let url: string =
			this.apiUrls.serviceBaseRest +
			"glosario/search" +
			"?token=" +
			token +
			text_lab +
			text_cat +
			text_act +
			"&q=" +
			palabra +
			text_page;
		// console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	filtros_glosario() {
		let url: string = this.apiUrls.serviceBaseRest + "glosario/selects";
		console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* / GLOSARIO *************************/

	/********************* VADEMECUM *************************/
	obtenerScorms(palabra?, cat_id?, subcat_id?: any, num_page?) {
		let text_cat = "";
		let text_subcat = "";
		let text_palabra = "";
		let text_num_page = "";

		if (palabra) text_palabra = palabra;
		if (cat_id) text_cat = `&category_id=${cat_id}`;
		if (subcat_id.length > 0) text_subcat = `&subcategory_id=${subcat_id}`;
		if (num_page) text_num_page = `&page=${num_page}`;

		let url: string =
			this.apiUrls.serviceBaseRest +
			`vademecum/search?q=${text_palabra + text_cat + text_subcat + text_num_page}`;
		// console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	categoriasScorms() {
		let url: string = this.apiUrls.serviceBaseRest + "vademecum/selects";
		// console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	subCategoriasScorms(categoria_id) {
		let url: string = this.apiUrls.serviceBaseRest + `vademecum/subcategorias/${categoria_id}`;
		// console.log(url);
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	visitaScorm(scorm_id) {
		let url: string = this.apiUrls.serviceBaseRest + `vademecum/store-visit/${scorm_id}`;
		console.log(url);
		let promesa = this.http
			.post(url, JSON.stringify({}))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	/********************* / VADEMECUM *************************/

	/********************* AULAS VIRTUALES *************************/
	getReuniones(status, num_page?) {
		let text_num_page = "";
		if (num_page) text_num_page = `&page=${num_page}`;

		let url: string =
			this.apiUrls.serviceBaseRest + `meetings/list?code=${status + text_num_page}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getStatusReuniones() {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/get-data`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	empezarReunion(meeting_id) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/${meeting_id}/start`;
		let promesa = this.http
			.post(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	unirseReunion(meeting_id) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/${meeting_id}/join`;
		let promesa = this.http
			.post(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	finalizarReunion(meeting_id) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/${meeting_id}/finish`;
		let promesa = this.http
			.post(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	getAttendants(filter) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/search-attendants`;
		let params: HttpParams = filter;
		console.log(params);
		let promesa = this.http
			.get(url, { params })
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	getGroups() {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/get-form-data`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	createReunion(data) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/store`;
		let promesa = this.http
			.post(url, data)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	editReunion(meeting_id) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/${meeting_id}/edit`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	updateReunion(meeting_id, data) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/${meeting_id}/update`;
		let promesa = this.http
			.put(url, data)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	duplicateReunion(meeting_id) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/${meeting_id}/duplicate-data`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	subirExcel(data) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/upload-attendants`;
		let promesa = this.http
			.post(url, data)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	cancelarReunion(meeting_id) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/${meeting_id}/cancel`;
		let promesa = this.http
			.put(url, {})
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}

	deletedReunion(meeting_id) {
		let url: string = this.apiUrls.serviceBaseRest + `meetings/${meeting_id}/destroy`;
		let promesa = this.http
			.delete(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		console.log(promesa);
		return promesa;
	}
	/********************* / AULAS VIRTUALES *************************/

	/********************* CURSOS PRESENCIALES *************************/

	getInPersonCourses(dateFilter,typeSessionFilter, num_page?) {
		let text_num_page = "";
		if (num_page) text_num_page = `&page=${num_page}`;
		let paramType = "";
		if (typeSessionFilter) paramType = `&type_session=${typeSessionFilter}`;


		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/list?code=${dateFilter + paramType + text_num_page}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getActiveOptions(idTopic) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${idTopic}/menu`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	getInPersonCourseData(idTopic) { //courses-in-person/topic/{topic}
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${idTopic}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	validateAttendanceMethod(idTopic, validationType) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${idTopic}/validate-resource?type=${validationType}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	multimediaIsAvailableMethod(idTopic, validationType) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${idTopic}/validate-resource?type=${validationType}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	validateAvailableExamMethod(idTopic, validationType) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${idTopic}/validate-resource?type=${validationType}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	validateAvailableSurveyMethod(idTopic, validationType) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${idTopic}/validate-resource?type=${validationType}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	setSurveyActiveMethod(idTopic) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${idTopic}/start-poll`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	sendSignatureOfCourseAttendance(canvaImgData, topicID) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${topicID}/upload-signature`;
		const token: string = localStorage.getItem("token");

		//Create form data
		const formData = new FormData();
		formData.append("signature",canvaImgData);

		//POST request using fetch() because http request doesn´t work
		return fetch(url, {
			// Adding method type
			method: "POST",
			// Adding body or contents to send
			body: formData,
			// Adding headers to the request
			headers: {
				'Authorization':`Bearer ${token}`,
				'Accept':`application/json, */*`
			}
		})
		.then( res => res.json())
		.catch(error => {
			console.log(error);
		});

	}

	getInPersonMultimedia(courseID, topicID) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/course/${courseID}/topic/${topicID}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getAttendanceList(courseID, topicID, attendanceCode, query) {
		let queryStr = "";
		//&search_user=Flor
		if (query) queryStr = `&search_user=${query}`;
		// course/{course_id}/topic/{topic_id}/assigned?code=present  (code = all,  pending,  present)
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/course/${courseID}/topic/${topicID}/assigned?code=${attendanceCode + queryStr}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	getQR(topicID) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${topicID}/users-init-data`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	postAttendanceOfUsers(courseID, usersID, action) {
		// Post: api/rest/courses-in-person/topic/{course_id}/take-assistance
		// Estructura: {
		// 	"user_ids":[17,55,56],
		// 	"action":"late"
		// }
		//- action (late,absent,attended)
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${courseID}/take-assistance`;

		let body = {
			user_ids: usersID,
			action: action,
		};

		let promesa = this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getEvaluationStatus(topicID) {
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/${topicID}/verify-evaluation-time`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	postExamData(topicID, action, time) {
		// Post: api/rest/courses-in-person/topic/{course_id}/take-assistance
		// Estructura: {
		// 	"user_ids":[17,55,56],
		// 	"action":"late"
		// }
		//- action (late,absent,attended)
		let url: string = this.apiUrls.serviceBaseRest + `courses-in-person/topic/evaluation`;

		let body = {
			topic_id: topicID,
			action: action,
			time: time
		};

		let promesa = this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* /CURSOS PRESENCIALES *************************/

	/********************* HISTORICO *************************/
	listarWorkspaceHistorico(){
		let url: string = this.apiUrls.serviceBaseRest + `reports/user-history-filters`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;

	}

	//api/rest/reports/user-history/:schoolId
	listarCursosHistorico( WorkspaceId ,schoolId?, searchValue?, page? ){
		let textWorkspaceId = "";
		let textSchoolId = "";
		let textSearch = "";
		let textPage = "";
		if (WorkspaceId) textWorkspaceId =`${WorkspaceId}`;
		if (schoolId) textSchoolId =`/${schoolId}`;
		// if (page) textPage =`&page=${page}`;
		if (page) textPage =`?page=${page}`;
		if (searchValue && !page) textSearch = `?search=${searchValue}`;
		if (searchValue && page) textSearch = `&search=${searchValue}`;
		let url: string = this.apiUrls.serviceBaseRest + `reports/user-history/${ textWorkspaceId + textSchoolId + textPage + textSearch }`;
		//reports/user-history/{workspaceId?}/{schoolId?}
		// let url: string = this.apiUrls.serviceBaseRest + `reports/user-history/${textSchoolId + textPage + textSearch }`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;

	}

	/********************* / HISTORICO *************************/


	/********************* NOTIFICACIONES *************************/

	ObtenerNotificaciones(num_page?) {
		let text_num_page = "";
		if (num_page) text_num_page = `?page=${num_page}`;

		let url: string = this.apiUrls.serviceBaseRest + `notification_user${text_num_page}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	RevisarNotificaciones(notificaciones_id) {
		let url: string = this.apiUrls.serviceBaseRest + `read_notification_user`;

		let promesa = this.http
			.post(url, notificaciones_id)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	/********************* / NOTIFICACIONES *************************/

	/********************* AYUDA FORMULARIO  *************************/

	guardarAyuda(form) {
		let url: string = this.apiUrls.serviceBaseRest + "registra_ayuda";
		console.log(url);

		let promesa = this.http
			.post(url, form)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerOpsAyuda() {
		let url: string = this.apiUrls.serviceBaseRest + "preguntas_seccion_ayuda";
		console.log(url);

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* / AYUDA FORMULARIO  *************************/


	/********************* ADJUNTAR ARCHIVO  *************************/

	adjuntarArchivo(file:File, description:string) {
		let url: string = this.apiUrls.serviceBaseRest + "usuario_upload_file";
		const token: string = localStorage.getItem("token");

		//Create form data
		const formData = new FormData();

		formData.append('file', file);
		formData.append('description', description);


		//*************************** */

		//POST request using fetch() because http request doesn´t work
		return fetch(url, {
			// Adding method type
			method: "POST",
			// Adding body or contents to send
			body: formData,
			// Adding headers to the request
			headers: {
				'Authorization':`Bearer ${token}`,
				'Accept':`application/json, */*`
			}
		}).then( res => res.json())

		//****************************** */

		// let promesa = this.http
		// .post(url, formData)
		// .toPromise()
		// .then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
		// .catch((error) => Promise.reject(error));
		// return promesa;

	}

	/********************* / ADJUNTAR ARCHIVO*************************/

	/********************* TAREAS *************************/

	getTaskData(taskID) {
		let url = this.apiUrls.serviceBaseRest + `tareas/${taskID}/search`;
		return this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	getTaskSummary() {
		let url = this.apiUrls.serviceBaseRest + `tareas/summary`;
		return this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	getTasksData(tasksStatus, school_id, course) {
		let url = this.apiUrls.serviceBaseRest + `tareas/user/${tasksStatus}`;
		if (school_id && school_id != '' && course && course != '') url = url + `?school_id=${school_id}`+ `&course=${course}`
		else {
			if (school_id && school_id != '') url = url + `?school_id=${school_id}`;
			if (course && course != '') url = url + `?course=${course}`;
		}
		return this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	taskUpdate(id_task, formData) {
		let url: string =  this.apiUrls.serviceBaseRest + `tareas/${id_task}/store-update`;
		const token: string = localStorage.getItem("token");

	   return fetch(url, {
		method: "POST",
		body: formData,
		headers: {
			'Authorization':`Bearer ${token}`,
			'Accept':`application/json, */*`
		}
	}).then( res => res.json())

   }

	/********************* / TAREAS *************************/

	/********************* EXTRAS *************************/

	// Funcion para Listar Encuestas libres
	lista_enc_libres() {
		// let url = this.apiUrls.serviceBaseRest + "lista_enc_libres/" + config_id;
		let url = this.apiUrls.serviceBaseRest + "lista_enc_libres";
		// console.log(url);
		return this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
	}

	verificar_versiones() {
		let url: string = this.apiUrls.serviceBaseRest + "app_versions";
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((res) => Promise.resolve(JSON.parse(JSON.stringify(res))))
			.catch((err) => Promise.reject(err));
		return promesa;
	}


	// Preguntas frecuentes
	cargarFaqs() {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + "preguntas_frecuentes")
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	subir_link(user_id: string, link: string, descripcion: string) {
		let url: string = this.apiUrls.serviceBaseRest + "usuario_upload_link";
		console.log(url);
		let params = new URLSearchParams();
		params.append("usuario_id", user_id.toString());
		params.append("link", link.toString());
		params.append("description", descripcion.toString());

		let promesa = this.http
			.post(url, params)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	listNPS(platform: string, version: string, dni: any) {
		let url: string = environment.SERVICE_BASE_REST_MASTER+'encuesta/listar?plataforma='+platform+'&version='+version+'&dni='+dni;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	sendNPS(body) {
		 let url: string =  environment.SERVICE_BASE_REST_MASTER+'encuesta/guardar';

		let promesa = this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	revisarContenido(tema_id, media_id, data) {
		let promesa = this.http
		.post(this.apiUrls.serviceBaseRest+ `temas/actividad_contenido_revisado/${tema_id}/${media_id}`, data)
			.toPromise()
			.then((res) => Promise.resolve(JSON.parse(JSON.stringify(res))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	duracionContenido(tema_id, media_id, data) {
		return new Promise((resolve, reject) => {
			this.http
				.post(this.apiUrls.serviceBaseRest + `temas/actividad_contenido_duracion/${tema_id}/${media_id}`, data)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/*********************  BENEFICIOS *************************/
	listarBeneficios(filtro?, page?, estado?, type?) {
		let url: string = this.apiUrls.serviceBaseRest + `benefits`;
		let params;
		if(page){
			params = {
				filtro:filtro,
				page: page,
				status: estado,
				type
			};
		} else{
			params = {
				filtro: filtro,
				status: estado,
				type
			};
		}

		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));

		return promesa;
	}

	obtenerBeneficio(id) {
		let url: string = this.apiUrls.serviceBaseRest + `benefits/${id}/show`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	registrarBeneficio(benefit, type) {
		let url: string = this.apiUrls.serviceBaseRest + `benefits/register`;

		let params = {
			benefit,
			type
		};

		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	obtenerSpeaker(id) {
		let url: string = this.apiUrls.serviceBaseRest + `benefits/speakers/${id}/show`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	listarFiltros() {
		let url: string = this.apiUrls.serviceBaseRest + `benefits/config`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	registrarEncuesta(benefit) {
		let url: string = this.apiUrls.serviceBaseRest + `benefits/polls/register`;
		let params = {
			benefit,
		};
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	actualizarEmail(email) {
		let url: string = this.apiUrls.serviceBaseRest + `actualizar_correo`;
		let params = {
			email,
		};
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	/********************* / EXTRAS *************************/


	/********************* MODULO DE RECONOCIMIENTO *************************/
	getCampaignsData(userId) {
		let url: string = this.apiUrls.serviceBase + `rest/votaciones/campaigns/${userId}`;

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getCampaignsContentsData(campaignId) {
		let url: string = this.apiUrls.serviceBase + `rest/votaciones/campaign/v2/contents/${campaignId}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	//todo: verificar-----------
	campaignContentsCheckReview(campaignID, contentID) {
		let url: string = this.apiUrls.serviceBase + `rest/votaciones/campaign/check-content/${campaignID}/${contentID}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getCampaignsRequirements(campaignId, userId) {
		let url: string = this.apiUrls.serviceBase + `rest/votaciones/campaign/requirements/${campaignId}/${userId}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	getUserBadges(campaignId, userId) {
		localStorage.removeItem("campaignBadges");
		let url: string = this.apiUrls.serviceBase + `rest/votaciones/campaign/badges/${campaignId}/${userId}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	postSurveyAnswer(campaignId, userId, answer) {
		let body = {
			campaign_id: campaignId,
   			user_id: userId,
   			answer: answer
		}
		return new Promise((resolve, reject) => {
			this.http
				.post(this.apiUrls.serviceBaseRest + `votaciones/campaign/contents/send_answer`, body)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	getAnswerStatus(campaignId, userId) {
		let url: string = this.apiUrls.serviceBaseRest + `votaciones/campaign/check/user_answer/${campaignId}/${userId}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	campaignBadges(campaignId, userId) {
		let url: string = this.apiUrls.serviceBaseRest + `votaciones/campaign/badges/${campaignId}/${userId}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getCoworkersListData(requirements,campaignID, userID, query, page, stateFilter) {
		const filter = stateFilter === null ? "" : stateFilter;
		let url:string;
		if(requirements) {
			//requirements.id  requirements.criterion_id
			url = this.apiUrls.serviceBaseRest + `votaciones/campaign/postulation/${campaignID}/${userID}?criterio_id=${requirements.criterion_id}&criterio_value_id=${requirements.id}&q=${query}&page=${page}&paginate=&active=${filter}`;
		} else {
			url = this.apiUrls.serviceBaseRest + `votaciones/campaign/postulation/${campaignID}/${userID}?criterio_id=&criterio_value_id=&q=${query}&page=${page}&paginate=&active=${filter}`;
		}

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	postSustentMessage(summonedId, userId, message) {
		let body = {
			summoned_id: summonedId,
   			user_id: userId,
   			sustent: message,
		}
		return new Promise((resolve, reject) => {
			this.http
				.post(this.apiUrls.serviceBaseRest + `votaciones/campaign/postulation/send_sustent`, body)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	checkPostulates(campaignId, userId) {
		let url: string = this.apiUrls.serviceBaseRest + `votaciones/campaign/check/user_postulate/${campaignId}/${userId}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getCandidatesListData(requirements,campaignID, userID, query, page) {
		let url:string;
		if(requirements) {
			url = this.apiUrls.serviceBaseRest + `votaciones/campaign/votation/candidates/${campaignID}/${userID}/${requirements.criterion_id}?criterio_value_id=${requirements.id}&q=${query}&page=${page}&paginate=`;
		} else {
			//CONFIRMAR EN CASO NO LLEGUE REQUERIMIENTOS EN ETAPA DE VOTACIÓN
			url = this.apiUrls.serviceBaseRest + `votaciones/campaign/votation/candidates/${campaignID}/${userID}/0?criterio_value_id=&q=${query}&page=${page}&paginate=`;
		}

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getCandidatesAlreadyVotedListData(campaignId, userID) {
		let url = this.apiUrls.serviceBaseRest + `votaciones/campaign/votation/user_votes/${campaignId}/${userID}?page=1&paginate=`;

		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	postVotes(candidates_ids, userId) {
		let body = {
			candidates_ids: candidates_ids,
   			user_id: userId,
		}
		return new Promise((resolve, reject) => {
			this.http
				.post(this.apiUrls.serviceBaseRest + `votaciones/campaign/votation/send_votes`, body)
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}


	/********************* / MODULO DE RECONOCIMIENTO *************************/

	/**************** NOTIFICACIONES  **************************/
	listNotifications() {
		let url: string = this.apiUrls.serviceBase + `users/notifications`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	hideNotification(id, hide) {
		let url: string = this.apiUrls.serviceBase + `users/notification/${id}`;
		let body = { hide: hide }
		let promesa = this.http
			.put(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	markAsReadNotification(id, markAsRead) {
		let url: string = this.apiUrls.serviceBase + `users/notification/${id}`;
		let body = { markAsRead: markAsRead }
		let promesa = this.http
			.put(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	/**************** NOTIFICACIONES  **************************/

	/**************** INVITADOS  **************************/

	verifyGuestUrlMultimarca(cod) {
		let url: string = environment.URL_MASTER + `/api/verify_guest_url_multimarca/${cod}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	verifyGuestUrl(cod) {
		let url: string = this.apiUrls.serviceBaseRest + `verify-guest-url?code=${cod}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	sendCodeGuest(email, name, lastname, document) {
		let url: string = this.apiUrls.serviceBaseRest + `send-code-guest`;
		let body = { email, name, lastname, document}
		let promesa = this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	verifyCodeGuest(email, code) {
		let url: string = this.apiUrls.serviceBaseRest + `verify-code-guest`;
		let body = { email, code }
		let promesa = this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	childCriterioValues(workspace_id, criterio_id, criterio_value_id) {
		let url: string = this.apiUrls.serviceBaseRest + `child-criterion-values?workspace_id=${workspace_id}&criterion_id=${criterio_id}&criterion_value_id=${criterio_value_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	storeGuest(workspace_id, code_id, guest_id, body) {
		let url: string = guest_id ?  this.apiUrls.serviceBaseRest + `store-guest?workspace_id=${workspace_id}&code_id=${code_id}&guest_id=${guest_id}` : this.apiUrls.serviceBaseRest + `store-guest?workspace_id=${workspace_id}&code_id=${code_id}`;
		let promesa = this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}
	/**************** INVITADOS  **************************/

	/**************** INDUCCIÓN  **************************/

	// User

	getProcessDataInstructions(process_id) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/data/instructions`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getProcessDataInstructionsSave(process_id) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/data/instructions/save`;
		let promesa = this.http
			.post(url,'')
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getProcessDataActivities(process_id) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/data`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

  sendSignatureActivityMethod(canvaImgData,processId, activity_id, model_id) {
		const token: string = localStorage.getItem("token");
    let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${processId}/activity/register`;
    //Create form data
		const formData = new FormData();
		formData.append("signature",canvaImgData);
		formData.append("activity_id",activity_id);
		formData.append("model_id",model_id);

		//POST request using fetch() because http request doesn´t work
		let promesa = fetch(url, {
			// Adding method type
			method: "POST",
			// Adding body or contents to send
			body: formData,
			// Adding headers to the request
			headers: {
				'Authorization':`Bearer ${token}`,
				'Accept':`application/json, */*`
			}
		})
		.then( res => Promise.resolve(res.json()) )
		.catch(error => Promise.reject(error));

    return promesa;
	}

  getDocumentToSign(processID,activityID) {
    //{{base_url}}processes/32/activity/firmas/216
    let url: string = this.apiUrls.serviceBaseRest + `processes/${processID}/activity/firmas/${activityID}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
  }

	// Lider (mentor)

	getPasantiasData() {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/pasantias-asignadas`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getPasantiaData(process_id, page, estado) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/pasantias-asignadas/${process_id}/data?page=${page}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;

	}

	getInfoPasantiaUser(user_id, pasantia_id) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/pasantia-user-info`;
		let params = {
			user_id,
			internship_id: pasantia_id
		};
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	saveInfoPasantiaUser(params) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/pasantia-user-info/save`;
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	// Supervisor

	getProcessesData() {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getProcessData(process_id) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/${process_id}/data`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;

	}

	getProcessDataStudents(process_id, page, query) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/${process_id}/data/students?page=${page}`;
    if(query) {
      url += `&q=${query}`
    }
		// let params;
		// if(page){
		// 	params = {
		// 		filtro:{
		// 			estado: estado,
		// 		},
		// 		page: page
		// 	};
		// }
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getProcessDataSupervisors(process_id) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/${process_id}/data/supervisors`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;

	}

	getInfoStudent(user_id) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/info_student/${user_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getUsersAbsencesMassive() {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/users_absences_massive`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;

	}

	saveAttendance(process_id, params) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/${process_id}/save_attendance`;
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	saveAttendanceMassive(params) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/save_attendance_massive`;
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	// Activities

	getActivityTopic(process_id, topic_id){
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/activity/topic/${topic_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getActivityMeeting(process_id, meeting_id){
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/activity/meeting/${meeting_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getActivityPoll(process_id, poll_id){
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/activity/poll/${poll_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getActivityAssessments(process_id, topic_id){
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/activity/questions/${topic_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	getActivityChecklist(process_id, checklist_id){
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/activity/checklist/${checklist_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

  getActivityPasantia(process_id, activity_id, filter){
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/activity/pasantia/${activity_id}?search=${filter}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	saveActivityPasantia(process_id, activity_id, user_id, data) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/activity/pasantia/${activity_id}/save`;
		let params = {
			user_id,
			data
		};
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	async cargarPregEncInduccion(process_id, poll_id) {
		if (process_id != null && poll_id != null) {
			await this.getActivityPoll(process_id, poll_id)
				.then(
					async (response) => {
						this.lista_encuesta = await response.data.questions;
					}
				)
				.catch((e) => {
					console.log(e.status, e.url);
				});
		}
	}


	getActivityChecklistUserByTrainer(process_id, activity_id, user_id){
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/${process_id}/activity/checklist_by_trainer/${activity_id}/${user_id}`;
		let promesa = this.http
			.get(url)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	registerUserChecklist(process_id,activity_id, user_id){
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/supervisor/processes/${process_id}/activity/register_user_checklist/${user_id}`;
		let params = {
			activity_id,
		};
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	saveActivity(process_id, activity_id, model_id) {
		let url: string = this.apiUrls.serviceBaseRest + `onboarding/user/processes/${process_id}/activity/register`;
		let params = {
			activity_id, model_id,
		};
		let promesa = this.http
			.post(url, JSON.stringify(params))
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	cargarFaqsInduction() {
		return new Promise((resolve, reject) => {
			this.http
				.get(this.apiUrls.serviceBaseRest + "onboarding/faqs")
				.toPromise()
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	/**************** INDUCCIÓN  **************************/
	/**************** DESCARGAS OFFLINE  **************************/
	getEncryptedMultimedia(mediaID, topicID) {
		// {{local}}/api/rest/temas/download/{tema_id}/{multimedia_id
		let url: string = this.apiUrls.serviceBaseRest + `temas/download/${topicID}/${mediaID}`;
		let promesa = this.http
			.get(url, {responseType: "blob"})
			.toPromise()
			.then((response) => Promise.resolve(response))
			.catch((error) => Promise.reject(error));
		return promesa;
	}

	sendAuditOffline(courseID, downloadType, start, end) {
		let url =  this.apiUrls.serviceBaseRest + `cursos/audit-offline`;
		let body = {
			course_id : courseID,
			type: downloadType,
			starts_at: start,
			finishes_at: end
		}
		return this.http
			.post(url, body)
			.toPromise()
			.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
			.catch((error) => Promise.reject(error));

	}
	// getEncryptedMultimedia(mediaID, topicID) {
	// 	// {{local}}/api/rest/temas/download/{tema_id}/{multimedia_id
	// 	let url: string = this.apiUrls.serviceBaseRest + `temas/download/${topicID}/${mediaID}`;
	// 	let promesa = this.http
	// 		.get(url)
	// 		.toPromise()
	// 		.then((response) => Promise.resolve(JSON.parse(JSON.stringify(response))))
	// 		.catch((error) => Promise.reject(error));
	// 	return promesa;
	// }


	// **************** REWARDS  **************************/
  getRewardsConfig() {
    //GET: {{local}}/api/rest/rewards/coins
		let url: string = this.apiUrls.serviceBaseRest + `rewards/coins`;
		return this.http.get<any>(url);
	}
  getRewardHistory(currentPage) {
		let url: string = this.apiUrls.serviceBaseRest + `rewards/coin-records?page=${currentPage}`;
		return this.http.get<any>(url);
	}
  checkRewardCertificate(courseId) {
		let url: string = this.apiUrls.serviceBaseRest + `rewards/claim-certificate-coins`;
    let body = {
			course_id: courseId
		}
		return this.http.post<any>(url, body);
	}

  // **************** checklist inverso & knockout **************************/
  sendKnockout(checklistId, body, entity_id?) {
		let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/checklist/${checklistId}/aplicate-knockout?entity_id=${entity_id}`;
		return this.http.post<any>(url, body);
	}
  getActivitiesPerScaleResults(checklist_id, audit_identifier,qualification_id, page, perPage,search = "") {
    let url: string = this.apiUrls.serviceBaseRest + `entrenamiento/v2/trainer/checklists/${checklist_id}/instance-activity-detail?audit_identifier=${audit_identifier}&qualification_id=${qualification_id}&page=${page ? page : '' }&per_page=${perPage}&search=${search}`;
		return this.http.get<any>(url);
	}
  // **************** login personalizado multimarca **************************/
  getMultibrandLoginConfig(urlOrigin) {
    // let url: string = environment.URL_MASTER + `/api/custom-login?domain=https://selvasystem.cursalab.io`;
    let url: string = environment.URL_MASTER + `/api/custom-login?domain=${urlOrigin}`;
		return this.http.get<any>(url);
	}

}
