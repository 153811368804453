<ng-container *ngIf="tooltipMsg">
    <!-- <ion-content class="ion-padding">{{tooltipMsg}}</ion-content> -->
    <div class="flex justify-center text-xs py-1.5 px-2 text-primary text-center">{{tooltipMsg}}</div>
    <!-- <ion-content class="ion-padding">Curso convalidado</ion-content> -->
</ng-container>

<ng-container *ngIf="options">
    <div class="flex flex-col items-start">
        <p *ngFor="let option of options" (click)="selectOption(option.code)" class="hover:bg-gray-100 py-1 px-2 w-full text-left">{{option.label}}</p>
    </div>
</ng-container>
