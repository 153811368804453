// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	APP_MULTIMARCA: true,
	URL_MASTER: "https://master.cursalab.co",
	// URL_MASTER: "https://test.master.cursalab.io",

	//**MULTIMARCA
	SERVICE_BASE: "",
	SERVICE_BASE_GESTOR: "",
	SERVICE_BASE_REST: "",
	SERVICE_BASE_AUTH: "",
	URL_BASE: "",
	URL_REPORTE: "",

	//**url de tiendas multimarca
	APP_PLAYSTORE: "https://play.google.com/store/apps/details?id=io.cursalab.app&hl=es",
	APP_ID_PLAYSTORE: "io.cursalab.app",
	APP_ID_IOS: "",

	//**versiones de multimarca
	APP_VERSION_IOS: "0.1",
	APP_VERSION_ANDROID: "8.2.4",
	WEB_VERSION: "8.2.4",

	//*****google analytics ID multimarca
	GA_TRACKING_ID:"G-PG6YEHQR59",

	SERVICE_BASE_REST_MASTER: "https://test.master.cursalab.io/api/",

	recaptcha:{
		siteKey: "6LeLUBAkAAAAAGIuPtfdvgzcpgAPHboYlvp1GdUf"
	},

	RUTA_ARCHIVOS: "archivos/",
	RUTA_DIPLOMA: "dnc/",
	RUTA_DESCARGAS: "dnx/",

	LOGO_APP: "assets/img/logo_color.png",
	LOGO_BY_CURSALAB: "assets/img/logo_by_cursalab.svg",
	LOGO_CURSALAB: "assets/img/logo-cursalab.svg",

	RUTA_TEMPLATE_INVITADOS:
		"https://d1rhexnbhr0x6m.cloudfront.net/inretail/plantillas/Plantilla-Importar-Invitados.xlsx",

  CUSTOM_SCHEMA: "io.cursalab.app://",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
