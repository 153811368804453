<div class="w-full h-full">
  <ngx-extended-pdf-viewer
  [customToolbar]="additionalButtons"
  [src]="pdfUrl"
  [textLayer]="true"
  (pdfLoaded)="afterLoadComplete($event)"
  (pageChange)="pagechanging($event)"
  delayFirstView="500"
  (pdfLoadingFailed)="onError($event)"
  >
</ngx-extended-pdf-viewer>
</div>

<ng-template #additionalButtons>
  <div id="toolbarViewer" style="background-color:rgb(234, 234, 236)" class="py-1 md:py-2">
    <div id="toolbarViewerLeft" class="hidden lg:block">
      <div class="toolbarButtonSpacer"></div>
      <pdf-find-button [showFindButton]="true" [textLayer]="true"></pdf-find-button>
      <pdf-paging-area></pdf-paging-area>
    </div>
    <pdf-zoom-toolbar ></pdf-zoom-toolbar> <!-- toolbar viewer middle -->
    <div id="toolbarViewerRight">
      <pdf-toggle-sidebar></pdf-toggle-sidebar>
    </div>

  </div>
</ng-template>
